import React from "react";
import "../../hero.css";

function hero() {
  return (
    <section className="hero">
      <div className="hero-image">
          <img src="superkick.png"  alt="Superkick logo" />
      </div>
      <p>A fantasy AFL football game</p>
      
      <div className="hero-buttons">
            <a href='https://play.google.com/store/apps/details?id=com.superkick_2023'><img class="google" alt='Get it on Google Play' src='google.png'/></a>
            <a href='https://apps.apple.com/us/app/afl-superkick/id1518998411'><img class="apple" alt='Get it on IOS' src='apple.png'/></a>
       </div>
    </section>
  );
}

export default hero;
