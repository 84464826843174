import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { sendForm } from 'emailjs-com'
import { toast } from 'react-toastify'

class Contact extends Component {

  constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          feedback: '',
          captcha: '',
          error: ''
        }
  }

  componentDidMount () {
    loadCaptchaEnginge(6); 
  };
      
  render() {
    return (
      <div class="home">
       <h1 class="title">Contact</h1>
       <div class="form-center">
        <form
          className="ui form"
          id={this.props.id}
          name={this.props.name}
          method={this.props.method}
          action={this.props.action}
        >
          <textarea
            id="name"
            name="name"
            onChange={this.handleInputChange.bind(this)}
            placeholder="your name"
            required
            value={this.state.name}
            style={{ width: "100%" }}
            rows={1}
          />
          <br />
          <textarea
            id="email"
            name="email"
            onChange={this.handleInputChange.bind(this)}
            placeholder="your email address"
            required
            value={this.state.email}
            error={this.state.error}
            style={{ width: "100%" }}
            rows={1}
          />
          <br />
          <textarea
            id="feedback"
            name="feedback"
            onChange={this.handleInputChange.bind(this)}
            placeholder="what is your enquiry?"
            required
            value={this.state.feedback}
            style={{ width: "100%", height: "250px" }}
          />
          <br />
          <div>
            <LoadCanvasTemplate />
            <textarea
             id="captcha"
              name="captcha"
              onChange={this.handleInputChange.bind(this)}
              placeholder="enter captcha"
              required
              value={this.state.captcha}
              style={{ width: "100%" }}
              rows={1}
          />
          </div> 
          <br />
          <input
            type="button"
            value="Send"
            className="ui button"
            style={{
              fontFamily: "Amatic SC",
              fontSize: "20px",
              color: "blue"
            }}
            onClick={this.sendMessage.bind(this)}
          />
        </form>
      </div>
    </div>
    )
  }


  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  sendMessage(event) {
    event.preventDefault();
    let user_captcha_value = this.state.captcha;
 
    if (validateCaptcha(user_captcha_value)) {
        if (!this.validateMail()) {
            return;
        }
        const templateParams = {
            from_name: this.state.name + " (" + this.state.email + ")",
            to_name: "jadderdevelopmenthouse@gmail.com",
            feedback: this.state.feedback
        };
        sendForm
            .send("service_tdt2auw", "template_zezimrv", templateParams, 'user_zECX8FAYfVG5qEUR73kn2')
            .then(
                function(response) {
                    toast.success("Your message has successfully sent!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                console.log("SUCCESS!", response.status, response.text);
            },
            function(err) {
                toast.error("Your message was not able to be sent");
            }
        );
        this.setState({
            name: "",
            email: "",
            feedback: ""
        });
    } else {
        alert("Error with validating captcha")
    }
  }
}

export default Contact;