import React, { Component } from 'react';

class Rules extends Component {
  render() {
    return (
      <div>
        <h1 className="title"> Superkick Rules</h1>
        <p>Welcome to Superkick for 2025 as the East Quiche and Pokie Bombs
        look to defend their premierships, I hope the rest of you are
        ready to try to take down the defending champions.</p>
       <p>With the success of last year we are continuing to have two leagues with some slight rule adjustments as well as name changes, ie: only one team per player. </p>
        <ol>
          <li>Superkick Melbourne</li>
          <li>Superkick Bendigo</li>
        </ol>
        <p>We will have 2 premiers but only 1 BH Cup winner.</p>
        <h2>1. GAMEPLAY RULES</h2>
        <p>The idea of the game is to select 25 goalscorers from afl teams of
        which the top 8 will score for your superkick side in your head to
        head games each week.</p> 
        <h3>Example Game</h3>
        <p>Flying Saucers:</p>
          <p>Scores</p> 
          <ol>
            <li>Franklin 6.1</li>
            <li>Wingard 4.2 </li>
            <li>Giansiracusa 2.0</li>
            <li>Henderson 1.2</li>
            <li>McIntosh 1.1</li>
            <li>Lynch 1.1 </li>
            <li>Sandilands 1.1</li>
            <li>Daniher 1.0</li>
          </ol>
           <p>Non counted scores</p>
           <ol>
            <li>Black 1.0</li>
            <li>Hill 0.3 </li>
            <li>Edwards 0.1</li>
          </ol>
          <p>Score: 17.8.110</p>
          <p>Lost to</p>
          <p>East Quiche:</p> 
          <p>Scores</p>
          <ol>
            <li>Pavlich 5.3</li>
            <li>Day 4.0</li>
            <li>Crameri 3.3</li>
            <li>Betts 2.2</li>
            <li>Cooney 1.1</li>
            <li>Ballantyne 1.0</li>
            <li>Carlisle 1.0</li>
            <li>Varcoe 0.3</li>
          </ol>
          <p>Non Counted Scores</p>
          <ol>
            <li>Brown 0.1 </li>
          </ol>
          <p>Score: 17.12.114</p>

          <p><i>In this scenario East Quiche would win by 4 points even though overall
          the Saucers had more goals but the 9th goalscorer will not count.</i></p>
        
          <h2>2. THE DRAFT</h2>
          <p>The draft order is decided via scorers in a practice match. Each team will pick 5 players each numbered 1 to 5. 
              The highest combined score will get pick 1. In the event of a tie, your highest picked player that is different from the team(s) that you tied with will decide the higher pick. 
              Eg Flying Fortress and Bamboozled Asians each score 8.5.53 but Fortress top pick was Bolton 2.4 and Asians top pick Amiss 2.1 would see Fortress get the higher pick. 
              If 2 teams pick 5 players in exact same order than your prediction will count. Eg Fremantle by 11.
          </p>

          <p>The draft is run in a snake order.</p>
          <ol>
            <li>1st Round is pick 1 to pick 10</li>
            <li>2nd round order is reversed, the team who had pick 1 now has pick 10.</li>
          </ol>

          <h2>3. PLAYER TRADING RULES/ADD ONS</h2>
          <ol>
            <li>Your side will eventually be made up of 25 but you can pass on draft day from pick 16 onwards. If you pass at pick 16 that will leave you 9 players to add on for the year. You could pass at pick 19 leaving you 6 add ons or take all 25 players on draft day leaving you zero add ons. 
                You can trade players out of your team from the unselected pool of players anytime prior to that players AFL teams 1st match of the year but no later than 1.20pm Saturday March 15 for any side. Trading will than not be possible for the rest of the year, only add ons. Your players are now fully locked in. Brisbane play Geelong in the opening round. Once that game starts you cannot trade out any player you selected from teams whose season has started. 
                Add ons can happen anytime after the draft. The cut off time for a new player to count for your week is the 1st Saturday of each round. Any add on after the 1st Saturday match for that round will count for the following round.
            </li>
            <li>
                There can be trading of your top 2 picks from the unselected pool of players if one of your top 2 picks misses 8 weeks. This is to minimise the extent a long term injury to a high quality player can have to your side. Injuries are a major case of bad luck in Superkick, not much can be done. If you pick Curnow as your 1st pick and you suspect he will miss 8 weeks, you can replace him with another player at no cost BUT you cannot bring Curnow back in if he returned sooner than 8 weeks. You think he might miss 6? You can replace him but he will not count again until he has missed 8 weeks for your Superkick side. 
            </li>
            <li>
              To make an add on in the Superkick comp you can make the trade yourself via the superkick app or ask myself or Chaz to do it for you. Obviously doing it yourself is the easiest way to beat another Superkick side to the punch. Announce any add ons in messenger group on facebook or again I will do it. Injury/Retirements during the year is a case of bad luck, using top ups wisely is key.
            </li>
          </ol>

          <h2>4. SEASON STRUCTURE</h2>
          <h3>Home and Away Structure</h3>
          <p>There will be 18 home and away games for each SK side with Opening round, Round 14 and Round 16 being our bye weeks.
              On those Rounds we will have our infamous BH Cup rounds. 
              Each team will play one another twice. We will be playing through some of the bye rounds, so if your team is short on players for that week compared to your opponent it is just a case of bad luck.
              How ladder poistion is calculated
              4 points will be awarded for a win and 2 points each for a draw, then percentage based on your own score divided by your opponents times 100.
          </p>
          <h3>Finals Structure</h3>
          <p>Teams 1-5 will play off in a top 5 finals format over 4 weeks (Rd 21-24) to decide the winner</p>
          
          <h4>Week 1</h4>
          <ul>
            <li>Qualifying Final 2v3</li>
            <li>Elimination Final 4v5</li>
          </ul>

          <h4>Week 2 - SEMI FINALS</h4>
          <ul>
            <li>1st Semi Final - Qualifying Final loser plays winner of Elimination Final</li>
            <li>2nd Semi Final - 1st plays the winner of the Qualifying Final</li>
          </ul> 
          <h4>Week 3 - PRELIMINARY FINAL</h4> 
          <p>Loser of 2nd Semi Final plays winner of 1st Semi Final</p>
          <h4>Week 4 - GRAND FINAL</h4>
          <p>Winner of 2nd Semi Final plays winner of Preliminary Final</p>


          <h3>A DRAWN FINAL</h3>
          <p>In the event of a drawn final it will be come down to the 9 th best scorer. If still a tie the 10 th best etc until a winner is found.
              If a winner is still undetermined it will be the team that had the biggest goalscorer for the match and if still a draw
          </p>
        
        <h2>PRIZES</h2>
        <h3>Cost of Entry is $120 entry per team</h3>
        <ol>
          <li>Winner $620</li>
          <li>Runner up $300</li>
          <li>3 rd Place playoff winner $180</li>
          <li>Highest season score $120</li>
          <li>BH Cup Winner $200</li>
        </ol>


        <p><b>Have fun at the draft</b></p>
        <p>Your League Administrator</p>
        <p>East Quiche Coach</p>
        <p>2014,15 and 2024 premiers</p>

        <p><i>Please note some rules are subjected to change</i></p>
      </div>
    )
  }
}

export default Rules;